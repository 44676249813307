import React from 'react';
import { css, before, after, StyleProps } from 'glamor';
import { theme } from '../theme';
import { Text } from './Text/Text';
import { LinkArea } from './LinkArea';
import { generateGradient } from '../utils/generateGradient';
import { Heading } from './Text/Heading';
import languageColors from '../data/language-colors.json';
import { GithubRepo } from '../pages/open-source';

interface FeaturedRepoCardProps extends StyleProps {
  name: string;
  description: string;
  github?: GithubRepo;
}

export const FeaturedRepoCard: React.SFC<FeaturedRepoCardProps> = ({
  name,
  description,
  github,
}) => {
  const languagesGradient =
    github && github.languages
      ? generateGradient(
          Object.keys(github.languages).map(name => ({
            color: (languageColors as any)[name],
            size: (github.languages as any)[name],
          })),
          '120deg'
        )
      : theme.color.grayDarker;

  return (
    <div
      css={{
        padding: '1rem',
        width: '100%',
        [theme.media.medium]: {
          width: '50%',
        },
      }}
    >
      <LinkArea
        css={css(
          {
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            boxShadow: `${theme.shadow.medium}, ${theme.shadow.large}`,
            padding: '1.5rem',
            cursor: 'pointer',
            transition: 'all 200ms ease-out',
            [theme.media.medium]: {
              '&:hover': {
                transform: `translateY(-.5rem)`,
                '&:after': {
                  transform: 'translateX(100%)',
                },
                '&:before': {
                  transform: 'translateX(0)',
                },
              },
            },
          },
          before({
            [theme.media.medium]: {
              transform: 'translateX(-100%)',
            },
            background: languagesGradient,
            transition: 'all 200ms ease-out',
            position: 'absolute',
            content: '""',
            height: 4,
            right: 0,
            left: 0,
            top: 0,
          }),
          after({
            display: 'none',
            [theme.media.medium]: {
              display: 'block',
            },
            background: theme.color.grayDarker,
            transition: 'all 200ms ease-out',
            position: 'absolute',
            content: '""',
            height: 4,
            right: 0,
            left: 0,
            top: 0,
          })
        )}
      >
        {({ link }) => (
          <>
            <div>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: '1rem',
                }}
              >
                <Heading
                  size={2}
                  css={{
                    fontWeight: theme.bold,
                  }}
                >
                  <a href={github ? github.url : ''} ref={link}>
                    {name}
                  </a>
                </Heading>
                {github &&
                  github.stars && (
                    <Text
                      size={2}
                      css={{ display: 'flex', alignItems: 'center' }}
                    >
                      <span aria-label="GitHub Stars" role="img">
                        ★
                      </span>
                      <span css={{ paddingLeft: '.25rem' }}>
                        {github.stars}
                      </span>
                    </Text>
                  )}
              </div>
              <Text
                size={2}
                css={{
                  marginBottom: '1rem',
                }}
              >
                {description}
              </Text>
            </div>
            <div
              css={{
                height: '1rem',
                display: 'flex',
              }}
            >
              {github &&
                github.languages &&
                Object.keys(github.languages).map(name => (
                  <Text
                    key={name}
                    color="gray"
                    size={3}
                    css={{ marginRight: '.5rem' }}
                  >
                    {name}
                  </Text>
                ))}
            </div>
          </>
        )}
      </LinkArea>
    </div>
  );
};
