interface ColorStop {
  color: string;
  size: number;
}

export function generateGradient(
  colorStops: ColorStop[],
  sideOrCorner: string
) {
  const totalSize = colorStops.reduce((acc, { size }) => acc + size, 0);

  let gradient = `linear-gradient(${sideOrCorner},`;
  let previousColor = colorStops[0].color;
  let cumulativePercent = 0;

  colorStops.forEach(({ color: currentColor, size }, index) => {
    const leftEdge = `${previousColor} ${cumulativePercent * 100}%`;
    const rightEdge = `${currentColor} ${cumulativePercent * 100}%`;

    cumulativePercent += size / totalSize;
    gradient = `${gradient} ${leftEdge}, ${rightEdge}`;

    if (index < colorStops.length - 1) {
      gradient = `${gradient},`;
    } else {
      gradient = `${gradient})`;
    }

    previousColor = currentColor;
  });

  return gradient;
}
