import React from 'react';
import languageColors from '../data/language-colors.json';
import { GithubRepo } from '../pages/open-source';
import { generateGradient } from '../utils/generateGradient';
import { theme } from '../theme';
import { LinkArea } from './LinkArea';
import { css, after, before } from 'glamor';
import { Heading } from './Text/Heading';
import { Text } from './Text/Text';

export const RepoCard: React.SFC<GithubRepo> = ({
  name,
  description,
  stars,
  url,
  languages,
}) => {
  const languagesGradient = Object.keys(languages).length
    ? generateGradient(
        Object.keys(languages).map(name => ({
          color: (languageColors as any)[name],
          size: (languages as any)[name],
        })),
        '120deg'
      )
    : 'black';

  return (
    <div
      css={{
        width: '100%',
        padding: '1rem',
        marginBottom: '1rem',
        [theme.media.medium]: {
          width: '50%',
        },
      }}
    >
      <LinkArea
        css={{
          display: 'flex',
          height: '100%',
          justifyContent: 'space-between',
          overflow: 'hidden',
          cursor: 'pointer',
          [theme.media.medium]: {
            '&:hover': {
              '& :after': {
                opacity: 1,
              },
            },
          },
        }}
      >
        {({ link }) => (
          <div
            css={{
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <div
              css={css(
                {
                  position: 'relative',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '1rem',
                },
                after({
                  [theme.media.medium]: {
                    opacity: 0,
                  },
                  background: languagesGradient,
                  transition: 'opacity 100ms ease-out',
                  position: 'absolute',
                  content: '""',
                  height: 2,
                  right: 0,
                  left: 0,
                  bottom: '-.25rem',
                }),
                before({
                  opacity: 1,
                  background: theme.color.grayDarker,
                  transition: 'all 200ms ease-out',
                  position: 'absolute',
                  content: '""',
                  height: 2,
                  right: 0,
                  left: 0,
                  bottom: '-.25rem',
                })
              )}
            >
              <Heading size={3}>
                <a href={url} ref={link}>
                  {name}
                </a>
              </Heading>
              {stars && (
                <Text size={2} css={{ display: 'flex', alignItems: 'center' }}>
                  <span aria-label="GitHub Stars" role="img">
                    ★
                  </span>
                  <span css={{ paddingLeft: '.25rem' }}>{stars}</span>
                </Text>
              )}
            </div>

            <Text size={2}>{description}</Text>
          </div>
        )}
      </LinkArea>
    </div>
  );
};
