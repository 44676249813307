import React from 'react';

export const AbstractCode: React.SFC<{}> = () => (
  <svg viewBox="0 0 1598 1169" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" fill="none" fillRule="evenodd">
      <g id="Group-3" fillRule="nonzero">
        <polygon
          id="Triangle"
          fill="#252527"
          transform="matrix(-1 0 0 1 1554 0)"
          points="300 0 1254 954 300 954"
        />
        <rect
          id="purple"
          fill="#5E21D9"
          x="1068"
          y="374"
          width="530"
          height="530"
        />
        <rect
          id="Green"
          fill="#1CF5BA"
          x="803"
          y="639"
          width="530"
          height="530"
        />
        <g
          id="Code"
          transform="translate(0 233)"
          fill="#DDE5E8"
          fillOpacity="0.75"
        >
          <g id="Group-10" transform="translate(250)">
            <rect width="250" height="25" rx="12.5" />
            <rect x="275" width="100" height="25" rx="12.5" />
            <rect x="400" width="400" height="25" rx="12.5" />
            <rect x="1050" width="275" height="25" rx="12.5" />
            <rect x="825" width="200" height="25" rx="12.5" />
          </g>
          <g id="Group-5" transform="translate(0 433)">
            <rect width="500" height="25" rx="12.5" />
            <rect x="525" width="100" height="25" rx="12.5" />
            <rect x="650" width="400" height="25" rx="12.5" />
            <rect x="1300" width="275" height="25" rx="12.5" />
            <rect x="1075" width="200" height="25" rx="12.5" />
          </g>
          <g id="Group-7" transform="translate(0 260)">
            <rect width="500" height="25" rx="12.5" />
            <rect x="525" width="100" height="25" rx="12.5" />
            <rect x="650" width="400" height="25" rx="12.5" />
            <rect x="1300" width="275" height="25" rx="12.5" />
            <rect x="1075" width="200" height="25" rx="12.5" />
          </g>
          <g id="Group-8" transform="translate(0 173)">
            <rect x="1075" width="350" height="25" rx="12.5" />
            <rect x="525" width="300" height="25" rx="12.5" />
            <rect width="500" height="25" rx="12.5" />
            <rect x="850" width="200" height="25" rx="12.5" />
          </g>
          <g id="Group-9" transform="translate(0 87)">
            <rect x="550" width="100" height="25" rx="12.5" />
            <rect x="1525" width="50" height="25" rx="12.5" />
            <rect x="1100" width="400" height="25" rx="12.5" />
            <rect x="675" width="400" height="25" rx="12.5" />
            <rect width="300" height="25" rx="12.5" />
            <rect x="325" width="200" height="25" rx="12.5" />
          </g>
          <g id="Group-4" transform="translate(0 520)">
            <rect x="550" width="100" height="25" rx="12.5" />
            <rect x="1525" width="50" height="25" rx="12.5" />
            <rect x="1100" width="400" height="25" rx="12.5" />
            <rect x="675" width="400" height="25" rx="12.5" />
            <rect width="300" height="25" rx="12.5" />
            <rect x="325" width="200" height="25" rx="12.5" />
          </g>
          <g id="Group-6" transform="translate(0 347)">
            <rect x="550" width="100" height="25" rx="12.5" />
            <rect x="1525" width="50" height="25" rx="12.5" />
            <rect x="1100" width="400" height="25" rx="12.5" />
            <rect x="675" width="400" height="25" rx="12.5" />
            <rect width="300" height="25" rx="12.5" />
            <rect x="325" width="200" height="25" rx="12.5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
