import React from 'react';
import differenceBy from 'lodash/differenceBy';
import { Layout } from '../layouts';
import { theme } from '../theme';
import { Footer } from '../components/Footer';
import { Container } from '../components/Container';
import { FeaturedRepoCard } from '../components/FeaturedRepoCard';
import { AccentHeading } from '../components/Text/AccentHeading';
import { Heading } from '../components/Text/Heading';
import { Text } from '../components/Text/Text';
import { Helmet } from 'react-helmet';
import featuredRepos from '../data/featured-repos.json';
import { RepoCard } from '../components/RepoCard';
import { Head } from '../components/Head';
import { AbstractCode } from '../components/Logos/AbstractCode';

const MIN_STAR_COUNT = 5;

const GITHUB_REPO_API_URL = 'https://palmerhq-gh-repos.now.sh';

export interface GithubRepo {
  name: string;
  owner: string;
  description: string;
  stars: number;
  url: string;
  languages: {
    [name: string]: number;
  };
}

interface OpenSourceProps {
  data: GatsbySchema.Query;
}

interface OpenSourceState {
  featuredGithubRepos: GithubRepo[];
  allGithubRepos: GithubRepo[];
}

export default class OpenSource extends React.Component<
  OpenSourceProps,
  OpenSourceState
> {
  state: OpenSourceState = {
    featuredGithubRepos: [],
    allGithubRepos: [],
  };

  async componentDidMount() {
    try {
      const allRequest = fetch(GITHUB_REPO_API_URL);

      const featuredResponse = await fetch(GITHUB_REPO_API_URL, {
        method: 'POST',
        body: JSON.stringify(
          featuredRepos.map(({ githubName: name, githubOwner: owner }) => ({
            name,
            owner,
          }))
        ),
      });
      const featuredGithubRepos: GithubRepo[] = await featuredResponse.json();
      this.setState({ featuredGithubRepos });

      const allResponse = await allRequest;
      const allGithubRepos: GithubRepo[] = await allResponse.json();
      this.setState({
        allGithubRepos: differenceBy(
          allGithubRepos,
          featuredGithubRepos,
          'name'
        ).filter(({ stars }) => MIN_STAR_COUNT <= stars),
      });
    } catch (error) {
      // do nothing, only the featured repos will be displayed
    }
  }

  render() {
    const { featuredGithubRepos, allGithubRepos } = this.state;

    return (
      <Layout>
        <Head
          title="Open Source Software"
          description="Open Source Software and Tools built by The Palmer Group."
        />
        <Container padding={['all']}>
          <div
            css={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '6rem',
              width: '100%',
            }}
          >
            <div css={{ flexGrow: 1 }}>
              <AccentHeading>What we're making</AccentHeading>
              <Heading component="h1">Open Source Software</Heading>
              <Text
                size={0}
                css={{
                  marginTop: '1rem',
                  maxWidth: 600,
                }}
              >
                Open Source plays a major role in how we build products and solutions. We give
                back to our community by creating valuable, free, and easy-to-use software and tools.
              </Text>
            </div>

            <div
              css={{
                display: 'none',
                [theme.media.medium]: {
                  display: 'block',
                  minWidth: '40%',
                  marginLeft: '3rem',
                },
              }}
            >
              <AbstractCode />
            </div>
          </div>

          <div
            css={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '0 -1rem 6rem -1rem',
            }}
          >
            {featuredRepos.map(({ name, description }, index) => {
              return (
                <FeaturedRepoCard
                  key={name}
                  name={name}
                  description={description}
                  github={featuredGithubRepos[index]}
                />
              );
            })}
          </div>
          <div
            css={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '0 -1rem 6rem -1rem',
            }}
          >
            {allGithubRepos.map(repo => (
              <RepoCard key={repo.url} {...repo} />
            ))}
          </div>
        </Container>
        <div css={{ marginTop: 'auto' }}>
          <Footer />
        </div>
      </Layout>
    );
  }
}
